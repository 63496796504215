"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * @skylords/api
 * Api server for the Skylords Reborn project
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BanControllerApiFetchParamCreator = exports.AntiCheatLogControllerApi = exports.AntiCheatLogControllerApiFactory = exports.AntiCheatLogControllerApiFp = exports.AntiCheatLogControllerApiFetchParamCreator = exports.AccountControllerApi = exports.AccountControllerApiFactory = exports.AccountControllerApiFp = exports.AccountControllerApiFetchParamCreator = exports.ScratchCodeBoosters = exports.ScratchCardUseTransaction = exports.ReportPartial = exports.Report = exports.QuestRewardReceiveTransaction = exports.PlayerTradeTransaction = exports.PlayerMailTransaction = exports.MatchRewardReceiveTransactionCardUpgrades = exports.MatchRewardReceiveTransaction = exports.Mail = exports.GmTakeTransaction = exports.GmMailTransaction = exports.GmGiveTransaction = exports.DeckPartial = exports.Deck = exports.CreateScratchCodeBoosters = exports.CosmeticBuyTransaction = exports.Cosmetic = exports.Chat = exports.ChangeBanStatus = exports.CardReforgeTransaction = exports.Card = exports.BoostersForgeTransaction = exports.BoosterOpenTransaction = exports.BoosterBuyTransaction = exports.Booster = exports.BfpReserveReceiveTransaction = exports.BfpHappyHourReceiveTransaction = exports.BfpDailyBoostReceiveTransaction = exports.AvatarBuyTransaction = exports.AuctionSellTransaction = exports.AuctionOutbidTransaction = exports.AuctionCreateTransaction = exports.AuctionCancelTransaction = exports.AuctionBidTransaction = exports.AntiCheatLog = exports.AccountPartial = exports.Account = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
exports.UserControllerApiFactory = exports.UserControllerApiFp = exports.UserControllerApiFetchParamCreator = exports.TransactionControllerApi = exports.TransactionControllerApiFactory = exports.TransactionControllerApiFp = exports.TransactionControllerApiFetchParamCreator = exports.StatControllerApi = exports.StatControllerApiFactory = exports.StatControllerApiFp = exports.StatControllerApiFetchParamCreator = exports.ScratchCodeControllerApi = exports.ScratchCodeControllerApiFactory = exports.ScratchCodeControllerApiFp = exports.ScratchCodeControllerApiFetchParamCreator = exports.ReportControllerApi = exports.ReportControllerApiFactory = exports.ReportControllerApiFp = exports.ReportControllerApiFetchParamCreator = exports.MailControllerApi = exports.MailControllerApiFactory = exports.MailControllerApiFp = exports.MailControllerApiFetchParamCreator = exports.DeckControllerApi = exports.DeckControllerApiFactory = exports.DeckControllerApiFp = exports.DeckControllerApiFetchParamCreator = exports.CosmeticControllerApi = exports.CosmeticControllerApiFactory = exports.CosmeticControllerApiFp = exports.CosmeticControllerApiFetchParamCreator = exports.ConnectionLogControllerApi = exports.ConnectionLogControllerApiFactory = exports.ConnectionLogControllerApiFp = exports.ConnectionLogControllerApiFetchParamCreator = exports.ChatControllerApi = exports.ChatControllerApiFactory = exports.ChatControllerApiFp = exports.ChatControllerApiFetchParamCreator = exports.CardControllerApi = exports.CardControllerApiFactory = exports.CardControllerApiFp = exports.CardControllerApiFetchParamCreator = exports.BoosterControllerApi = exports.BoosterControllerApiFactory = exports.BoosterControllerApiFp = exports.BoosterControllerApiFetchParamCreator = exports.BanControllerApi = exports.BanControllerApiFactory = exports.BanControllerApiFp = void 0;
exports.UserControllerApi = void 0;
const url = require("url");
const isomorphicFetch = require("isomorphic-fetch");
const BASE_PATH = "/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
class BaseAPI {
    basePath;
    fetch;
    configuration;
    constructor(configuration, basePath = BASE_PATH, fetch = isomorphicFetch) {
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}
exports.BaseAPI = BaseAPI;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
class RequiredError extends Error {
    field;
    name;
    constructor(field, msg) {
        super(msg);
        this.field = field;
    }
}
exports.RequiredError = RequiredError;
/**
 * @export
 * @namespace Account
 */
var Account;
(function (Account) {
    /**
     * @export
     * @enum {string}
     */
    let MutedEnum;
    (function (MutedEnum) {
        MutedEnum["MutedPrivate"] = "mutedPrivate";
        MutedEnum["MutedPrivateAndPublic"] = "mutedPrivateAndPublic";
        MutedEnum["MutedPublic"] = "mutedPublic";
        MutedEnum["NotMuted"] = "notMuted";
    })(MutedEnum = Account.MutedEnum || (Account.MutedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let RankEnum;
    (function (RankEnum) {
        RankEnum["Bot"] = "bot";
        RankEnum["Normal"] = "normal";
        RankEnum["StaffAdmin"] = "staffAdmin";
        RankEnum["StaffArtist"] = "staffArtist";
        RankEnum["StaffCommunityManager"] = "staffCommunityManager";
        RankEnum["StaffDesigner"] = "staffDesigner";
        RankEnum["StaffDeveloper"] = "staffDeveloper";
        RankEnum["StaffEventOrganizer"] = "staffEventOrganizer";
        RankEnum["StaffGeneral"] = "staffGeneral";
        RankEnum["StaffLeadDeveloper"] = "staffLeadDeveloper";
        RankEnum["StaffMarketeer"] = "staffMarketeer";
        RankEnum["StaffModerator"] = "staffModerator";
        RankEnum["StaffProjectCoordinator"] = "staffProjectCoordinator";
    })(RankEnum = Account.RankEnum || (Account.RankEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let SelectedDailyQuestPoolEnum;
    (function (SelectedDailyQuestPoolEnum) {
        SelectedDailyQuestPoolEnum["Pve"] = "pve";
        SelectedDailyQuestPoolEnum["PveAndPvp"] = "pveAndPvp";
        SelectedDailyQuestPoolEnum["Pvp"] = "pvp";
    })(SelectedDailyQuestPoolEnum = Account.SelectedDailyQuestPoolEnum || (Account.SelectedDailyQuestPoolEnum = {}));
})(Account || (exports.Account = Account = {}));
/**
 * @export
 * @namespace AccountPartial
 */
var AccountPartial;
(function (AccountPartial) {
    /**
     * @export
     * @enum {string}
     */
    let MutedEnum;
    (function (MutedEnum) {
        MutedEnum["MutedPrivate"] = "mutedPrivate";
        MutedEnum["MutedPrivateAndPublic"] = "mutedPrivateAndPublic";
        MutedEnum["MutedPublic"] = "mutedPublic";
        MutedEnum["NotMuted"] = "notMuted";
    })(MutedEnum = AccountPartial.MutedEnum || (AccountPartial.MutedEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let RankEnum;
    (function (RankEnum) {
        RankEnum["Bot"] = "bot";
        RankEnum["Normal"] = "normal";
        RankEnum["StaffAdmin"] = "staffAdmin";
        RankEnum["StaffArtist"] = "staffArtist";
        RankEnum["StaffCommunityManager"] = "staffCommunityManager";
        RankEnum["StaffDesigner"] = "staffDesigner";
        RankEnum["StaffDeveloper"] = "staffDeveloper";
        RankEnum["StaffEventOrganizer"] = "staffEventOrganizer";
        RankEnum["StaffGeneral"] = "staffGeneral";
        RankEnum["StaffLeadDeveloper"] = "staffLeadDeveloper";
        RankEnum["StaffMarketeer"] = "staffMarketeer";
        RankEnum["StaffModerator"] = "staffModerator";
        RankEnum["StaffProjectCoordinator"] = "staffProjectCoordinator";
    })(RankEnum = AccountPartial.RankEnum || (AccountPartial.RankEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let SelectedDailyQuestPoolEnum;
    (function (SelectedDailyQuestPoolEnum) {
        SelectedDailyQuestPoolEnum["Pve"] = "pve";
        SelectedDailyQuestPoolEnum["PveAndPvp"] = "pveAndPvp";
        SelectedDailyQuestPoolEnum["Pvp"] = "pvp";
    })(SelectedDailyQuestPoolEnum = AccountPartial.SelectedDailyQuestPoolEnum || (AccountPartial.SelectedDailyQuestPoolEnum = {}));
})(AccountPartial || (exports.AccountPartial = AccountPartial = {}));
/**
 * @export
 * @namespace AntiCheatLog
 */
var AntiCheatLog;
(function (AntiCheatLog) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AccountChange"] = "accountChange";
        TypeEnum["ClientFileModification"] = "clientFileModification";
        TypeEnum["General"] = "general";
        TypeEnum["GuaranteedPregameCheat"] = "guaranteedPregameCheat";
        TypeEnum["MatchCheat"] = "matchCheat";
        TypeEnum["ModeratorNote"] = "moderatorNote";
        TypeEnum["PossiblePregameCheat"] = "possiblePregameCheat";
    })(TypeEnum = AntiCheatLog.TypeEnum || (AntiCheatLog.TypeEnum = {}));
})(AntiCheatLog || (exports.AntiCheatLog = AntiCheatLog = {}));
/**
 * @export
 * @namespace AuctionBidTransaction
 */
var AuctionBidTransaction;
(function (AuctionBidTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AuctionBid"] = "auctionBid";
    })(TypeEnum = AuctionBidTransaction.TypeEnum || (AuctionBidTransaction.TypeEnum = {}));
})(AuctionBidTransaction || (exports.AuctionBidTransaction = AuctionBidTransaction = {}));
/**
 * @export
 * @namespace AuctionCancelTransaction
 */
var AuctionCancelTransaction;
(function (AuctionCancelTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AuctionCancel"] = "auctionCancel";
    })(TypeEnum = AuctionCancelTransaction.TypeEnum || (AuctionCancelTransaction.TypeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let ReasonEnum;
    (function (ReasonEnum) {
        ReasonEnum["CancelledByPlayer"] = "cancelledByPlayer";
        ReasonEnum["Expired"] = "expired";
    })(ReasonEnum = AuctionCancelTransaction.ReasonEnum || (AuctionCancelTransaction.ReasonEnum = {}));
})(AuctionCancelTransaction || (exports.AuctionCancelTransaction = AuctionCancelTransaction = {}));
/**
 * @export
 * @namespace AuctionCreateTransaction
 */
var AuctionCreateTransaction;
(function (AuctionCreateTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AuctionCreate"] = "auctionCreate";
    })(TypeEnum = AuctionCreateTransaction.TypeEnum || (AuctionCreateTransaction.TypeEnum = {}));
})(AuctionCreateTransaction || (exports.AuctionCreateTransaction = AuctionCreateTransaction = {}));
/**
 * @export
 * @namespace AuctionOutbidTransaction
 */
var AuctionOutbidTransaction;
(function (AuctionOutbidTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AuctionOutbid"] = "auctionOutbid";
    })(TypeEnum = AuctionOutbidTransaction.TypeEnum || (AuctionOutbidTransaction.TypeEnum = {}));
})(AuctionOutbidTransaction || (exports.AuctionOutbidTransaction = AuctionOutbidTransaction = {}));
/**
 * @export
 * @namespace AuctionSellTransaction
 */
var AuctionSellTransaction;
(function (AuctionSellTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AuctionSell"] = "auctionSell";
    })(TypeEnum = AuctionSellTransaction.TypeEnum || (AuctionSellTransaction.TypeEnum = {}));
})(AuctionSellTransaction || (exports.AuctionSellTransaction = AuctionSellTransaction = {}));
/**
 * @export
 * @namespace AvatarBuyTransaction
 */
var AvatarBuyTransaction;
(function (AvatarBuyTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["AvatarBuy"] = "avatarBuy";
    })(TypeEnum = AvatarBuyTransaction.TypeEnum || (AvatarBuyTransaction.TypeEnum = {}));
})(AvatarBuyTransaction || (exports.AvatarBuyTransaction = AvatarBuyTransaction = {}));
/**
 * @export
 * @namespace BfpDailyBoostReceiveTransaction
 */
var BfpDailyBoostReceiveTransaction;
(function (BfpDailyBoostReceiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BfpDailyBoostReceive"] = "bfpDailyBoostReceive";
    })(TypeEnum = BfpDailyBoostReceiveTransaction.TypeEnum || (BfpDailyBoostReceiveTransaction.TypeEnum = {}));
})(BfpDailyBoostReceiveTransaction || (exports.BfpDailyBoostReceiveTransaction = BfpDailyBoostReceiveTransaction = {}));
/**
 * @export
 * @namespace BfpHappyHourReceiveTransaction
 */
var BfpHappyHourReceiveTransaction;
(function (BfpHappyHourReceiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BfpHappyHourReceive"] = "bfpHappyHourReceive";
    })(TypeEnum = BfpHappyHourReceiveTransaction.TypeEnum || (BfpHappyHourReceiveTransaction.TypeEnum = {}));
})(BfpHappyHourReceiveTransaction || (exports.BfpHappyHourReceiveTransaction = BfpHappyHourReceiveTransaction = {}));
/**
 * @export
 * @namespace BfpReserveReceiveTransaction
 */
var BfpReserveReceiveTransaction;
(function (BfpReserveReceiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BfpReserveReceive"] = "bfpReserveReceive";
    })(TypeEnum = BfpReserveReceiveTransaction.TypeEnum || (BfpReserveReceiveTransaction.TypeEnum = {}));
})(BfpReserveReceiveTransaction || (exports.BfpReserveReceiveTransaction = BfpReserveReceiveTransaction = {}));
/**
 * @export
 * @namespace Booster
 */
var Booster;
(function (Booster) {
    /**
     * @export
     * @enum {string}
     */
    let EditionEnum;
    (function (EditionEnum) {
        EditionEnum["All"] = "all";
        EditionEnum["Bandits"] = "bandits";
        EditionEnum["Fire"] = "fire";
        EditionEnum["FireFrost"] = "fireFrost";
        EditionEnum["Frost"] = "frost";
        EditionEnum["LostSouls"] = "lostSouls";
        EditionEnum["Mini"] = "mini";
        EditionEnum["Nature"] = "nature";
        EditionEnum["NatureShadow"] = "natureShadow";
        EditionEnum["Rebirth"] = "rebirth";
        EditionEnum["Shadow"] = "shadow";
        EditionEnum["Stonekin"] = "stonekin";
        EditionEnum["Twilight"] = "twilight";
    })(EditionEnum = Booster.EditionEnum || (Booster.EditionEnum = {}));
})(Booster || (exports.Booster = Booster = {}));
/**
 * @export
 * @namespace BoosterBuyTransaction
 */
var BoosterBuyTransaction;
(function (BoosterBuyTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BoosterBuy"] = "boosterBuy";
    })(TypeEnum = BoosterBuyTransaction.TypeEnum || (BoosterBuyTransaction.TypeEnum = {}));
})(BoosterBuyTransaction || (exports.BoosterBuyTransaction = BoosterBuyTransaction = {}));
/**
 * @export
 * @namespace BoosterOpenTransaction
 */
var BoosterOpenTransaction;
(function (BoosterOpenTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BoosterOpen"] = "boosterOpen";
    })(TypeEnum = BoosterOpenTransaction.TypeEnum || (BoosterOpenTransaction.TypeEnum = {}));
})(BoosterOpenTransaction || (exports.BoosterOpenTransaction = BoosterOpenTransaction = {}));
/**
 * @export
 * @namespace BoostersForgeTransaction
 */
var BoostersForgeTransaction;
(function (BoostersForgeTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["BoostersForge"] = "boostersForge";
    })(TypeEnum = BoostersForgeTransaction.TypeEnum || (BoostersForgeTransaction.TypeEnum = {}));
})(BoostersForgeTransaction || (exports.BoostersForgeTransaction = BoostersForgeTransaction = {}));
/**
 * @export
 * @namespace Card
 */
var Card;
(function (Card) {
    /**
     * @export
     * @enum {string}
     */
    let AffinityEnum;
    (function (AffinityEnum) {
        AffinityEnum["Fire"] = "fire";
        AffinityEnum["Frost"] = "frost";
        AffinityEnum["Nature"] = "nature";
        AffinityEnum["Shadow"] = "shadow";
    })(AffinityEnum = Card.AffinityEnum || (Card.AffinityEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let EditionEnum;
    (function (EditionEnum) {
        EditionEnum["Amii"] = "amii";
        EditionEnum["LostSouls"] = "lostSouls";
        EditionEnum["Renegade"] = "renegade";
        EditionEnum["Skylords"] = "skylords";
        EditionEnum["Twilight"] = "twilight";
    })(EditionEnum = Card.EditionEnum || (Card.EditionEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let FactionEnum;
    (function (FactionEnum) {
        FactionEnum["Bandits"] = "bandits";
        FactionEnum["Fire"] = "fire";
        FactionEnum["FireFrost"] = "fireFrost";
        FactionEnum["Frost"] = "frost";
        FactionEnum["Legendary"] = "legendary";
        FactionEnum["LostSouls"] = "lostSouls";
        FactionEnum["Nature"] = "nature";
        FactionEnum["NatureShadow"] = "natureShadow";
        FactionEnum["Shadow"] = "shadow";
        FactionEnum["Stonekin"] = "stonekin";
        FactionEnum["Twilight"] = "twilight";
    })(FactionEnum = Card.FactionEnum || (Card.FactionEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let RarityEnum;
    (function (RarityEnum) {
        RarityEnum["Common"] = "common";
        RarityEnum["Rare"] = "rare";
        RarityEnum["UltraRare"] = "ultraRare";
        RarityEnum["Uncommon"] = "uncommon";
    })(RarityEnum = Card.RarityEnum || (Card.RarityEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["Building"] = "building";
        TypeEnum["Spell"] = "spell";
        TypeEnum["Unit"] = "unit";
    })(TypeEnum = Card.TypeEnum || (Card.TypeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let UpgradeLevelEnum;
    (function (UpgradeLevelEnum) {
        UpgradeLevelEnum["Base"] = "base";
        UpgradeLevelEnum["Promo"] = "promo";
        UpgradeLevelEnum["Upgrade1"] = "upgrade1";
        UpgradeLevelEnum["Upgrade2"] = "upgrade2";
        UpgradeLevelEnum["Upgrade3"] = "upgrade3";
    })(UpgradeLevelEnum = Card.UpgradeLevelEnum || (Card.UpgradeLevelEnum = {}));
})(Card || (exports.Card = Card = {}));
/**
 * @export
 * @namespace CardReforgeTransaction
 */
var CardReforgeTransaction;
(function (CardReforgeTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["CardReforge"] = "cardReforge";
    })(TypeEnum = CardReforgeTransaction.TypeEnum || (CardReforgeTransaction.TypeEnum = {}));
})(CardReforgeTransaction || (exports.CardReforgeTransaction = CardReforgeTransaction = {}));
/**
 * @export
 * @namespace ChangeBanStatus
 */
var ChangeBanStatus;
(function (ChangeBanStatus) {
    /**
     * @export
     * @enum {string}
     */
    let BanStatusEnum;
    (function (BanStatusEnum) {
        BanStatusEnum["NotBanned"] = "notBanned";
        BanStatusEnum["Banned"] = "banned";
        BanStatusEnum["HwidBanned"] = "hwidBanned";
    })(BanStatusEnum = ChangeBanStatus.BanStatusEnum || (ChangeBanStatus.BanStatusEnum = {}));
})(ChangeBanStatus || (exports.ChangeBanStatus = ChangeBanStatus = {}));
/**
 * @export
 * @namespace Chat
 */
var Chat;
(function (Chat) {
    /**
     * @export
     * @enum {string}
     */
    let TeamTypeEnum;
    (function (TeamTypeEnum) {
        TeamTypeEnum["InvalidTeam"] = "invalidTeam";
        TeamTypeEnum["Team1"] = "team1";
        TeamTypeEnum["Team2"] = "team2";
        TeamTypeEnum["Team3"] = "team3";
        TeamTypeEnum["Unteamed"] = "unteamed";
    })(TeamTypeEnum = Chat.TeamTypeEnum || (Chat.TeamTypeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["Beginner"] = "beginner";
        TypeEnum["CommunityMaps"] = "communityMaps";
        TypeEnum["Global"] = "global";
        TypeEnum["Group"] = "group";
        TypeEnum["LookingForGroup"] = "lookingForGroup";
        TypeEnum["Match"] = "match";
        TypeEnum["Others"] = "others";
        TypeEnum["RankedPvp"] = "rankedPvp";
        TypeEnum["Region"] = "region";
        TypeEnum["SparringPvp"] = "sparringPvp";
        TypeEnum["Strategy"] = "strategy";
        TypeEnum["Team"] = "team";
        TypeEnum["Trade"] = "trade";
        TypeEnum["Whisper"] = "whisper";
    })(TypeEnum = Chat.TypeEnum || (Chat.TypeEnum = {}));
})(Chat || (exports.Chat = Chat = {}));
/**
 * @export
 * @namespace Cosmetic
 */
var Cosmetic;
(function (Cosmetic) {
    /**
     * @export
     * @enum {string}
     */
    let AvailabilityEnum;
    (function (AvailabilityEnum) {
        AvailabilityEnum["None"] = "none";
        AvailabilityEnum["Obtainable"] = "obtainable";
        AvailabilityEnum["Purchasable"] = "purchasable";
    })(AvailabilityEnum = Cosmetic.AvailabilityEnum || (Cosmetic.AvailabilityEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["Border"] = "border";
        TypeEnum["Portrait"] = "portrait";
        TypeEnum["Texture"] = "texture";
        TypeEnum["Title"] = "title";
    })(TypeEnum = Cosmetic.TypeEnum || (Cosmetic.TypeEnum = {}));
})(Cosmetic || (exports.Cosmetic = Cosmetic = {}));
/**
 * @export
 * @namespace CosmeticBuyTransaction
 */
var CosmeticBuyTransaction;
(function (CosmeticBuyTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["CosmeticBuy"] = "cosmeticBuy";
    })(TypeEnum = CosmeticBuyTransaction.TypeEnum || (CosmeticBuyTransaction.TypeEnum = {}));
})(CosmeticBuyTransaction || (exports.CosmeticBuyTransaction = CosmeticBuyTransaction = {}));
/**
 * @export
 * @namespace CreateScratchCodeBoosters
 */
var CreateScratchCodeBoosters;
(function (CreateScratchCodeBoosters) {
    /**
     * @export
     * @enum {string}
     */
    let EditionEnum;
    (function (EditionEnum) {
        EditionEnum["All"] = "all";
        EditionEnum["Bandits"] = "bandits";
        EditionEnum["Fire"] = "fire";
        EditionEnum["FireFrost"] = "fireFrost";
        EditionEnum["Frost"] = "frost";
        EditionEnum["LostSouls"] = "lostSouls";
        EditionEnum["Mini"] = "mini";
        EditionEnum["Nature"] = "nature";
        EditionEnum["NatureShadow"] = "natureShadow";
        EditionEnum["Rebirth"] = "rebirth";
        EditionEnum["Shadow"] = "shadow";
        EditionEnum["Stonekin"] = "stonekin";
        EditionEnum["Twilight"] = "twilight";
    })(EditionEnum = CreateScratchCodeBoosters.EditionEnum || (CreateScratchCodeBoosters.EditionEnum = {}));
})(CreateScratchCodeBoosters || (exports.CreateScratchCodeBoosters = CreateScratchCodeBoosters = {}));
/**
 * @export
 * @namespace Deck
 */
var Deck;
(function (Deck) {
    /**
     * @export
     * @enum {string}
     */
    let CardPoolEnum;
    (function (CardPoolEnum) {
        CardPoolEnum["Collection"] = "collection";
        CardPoolEnum["FreePvp"] = "freePvp";
        CardPoolEnum["Tutorial"] = "tutorial";
    })(CardPoolEnum = Deck.CardPoolEnum || (Deck.CardPoolEnum = {}));
})(Deck || (exports.Deck = Deck = {}));
/**
 * @export
 * @namespace DeckPartial
 */
var DeckPartial;
(function (DeckPartial) {
    /**
     * @export
     * @enum {string}
     */
    let CardPoolEnum;
    (function (CardPoolEnum) {
        CardPoolEnum["Collection"] = "collection";
        CardPoolEnum["FreePvp"] = "freePvp";
        CardPoolEnum["Tutorial"] = "tutorial";
    })(CardPoolEnum = DeckPartial.CardPoolEnum || (DeckPartial.CardPoolEnum = {}));
})(DeckPartial || (exports.DeckPartial = DeckPartial = {}));
/**
 * @export
 * @namespace GmGiveTransaction
 */
var GmGiveTransaction;
(function (GmGiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["GmGive"] = "gmGive";
    })(TypeEnum = GmGiveTransaction.TypeEnum || (GmGiveTransaction.TypeEnum = {}));
})(GmGiveTransaction || (exports.GmGiveTransaction = GmGiveTransaction = {}));
/**
 * @export
 * @namespace GmMailTransaction
 */
var GmMailTransaction;
(function (GmMailTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["GmMail"] = "gmMail";
    })(TypeEnum = GmMailTransaction.TypeEnum || (GmMailTransaction.TypeEnum = {}));
})(GmMailTransaction || (exports.GmMailTransaction = GmMailTransaction = {}));
/**
 * @export
 * @namespace GmTakeTransaction
 */
var GmTakeTransaction;
(function (GmTakeTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["GmTake"] = "gmTake";
    })(TypeEnum = GmTakeTransaction.TypeEnum || (GmTakeTransaction.TypeEnum = {}));
})(GmTakeTransaction || (exports.GmTakeTransaction = GmTakeTransaction = {}));
/**
 * @export
 * @namespace Mail
 */
var Mail;
(function (Mail) {
    /**
     * @export
     * @enum {string}
     */
    let StatusEnum;
    (function (StatusEnum) {
        StatusEnum["New"] = "new";
        StatusEnum["Read"] = "read";
        StatusEnum["Returned"] = "returned";
    })(StatusEnum = Mail.StatusEnum || (Mail.StatusEnum = {}));
})(Mail || (exports.Mail = Mail = {}));
/**
 * @export
 * @namespace MatchRewardReceiveTransaction
 */
var MatchRewardReceiveTransaction;
(function (MatchRewardReceiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["MatchRewardReceive"] = "matchRewardReceive";
    })(TypeEnum = MatchRewardReceiveTransaction.TypeEnum || (MatchRewardReceiveTransaction.TypeEnum = {}));
})(MatchRewardReceiveTransaction || (exports.MatchRewardReceiveTransaction = MatchRewardReceiveTransaction = {}));
/**
 * @export
 * @namespace MatchRewardReceiveTransactionCardUpgrades
 */
var MatchRewardReceiveTransactionCardUpgrades;
(function (MatchRewardReceiveTransactionCardUpgrades) {
    /**
     * @export
     * @enum {string}
     */
    let LevelEnum;
    (function (LevelEnum) {
        LevelEnum[LevelEnum["NUMBER_1"] = 1] = "NUMBER_1";
        LevelEnum[LevelEnum["NUMBER_2"] = 2] = "NUMBER_2";
        LevelEnum[LevelEnum["NUMBER_3"] = 3] = "NUMBER_3";
    })(LevelEnum = MatchRewardReceiveTransactionCardUpgrades.LevelEnum || (MatchRewardReceiveTransactionCardUpgrades.LevelEnum = {}));
})(MatchRewardReceiveTransactionCardUpgrades || (exports.MatchRewardReceiveTransactionCardUpgrades = MatchRewardReceiveTransactionCardUpgrades = {}));
/**
 * @export
 * @namespace PlayerMailTransaction
 */
var PlayerMailTransaction;
(function (PlayerMailTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["PlayerMail"] = "playerMail";
    })(TypeEnum = PlayerMailTransaction.TypeEnum || (PlayerMailTransaction.TypeEnum = {}));
})(PlayerMailTransaction || (exports.PlayerMailTransaction = PlayerMailTransaction = {}));
/**
 * @export
 * @namespace PlayerTradeTransaction
 */
var PlayerTradeTransaction;
(function (PlayerTradeTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["PlayerTrade"] = "playerTrade";
    })(TypeEnum = PlayerTradeTransaction.TypeEnum || (PlayerTradeTransaction.TypeEnum = {}));
})(PlayerTradeTransaction || (exports.PlayerTradeTransaction = PlayerTradeTransaction = {}));
/**
 * @export
 * @namespace QuestRewardReceiveTransaction
 */
var QuestRewardReceiveTransaction;
(function (QuestRewardReceiveTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["QuestRewardReceive"] = "questRewardReceive";
    })(TypeEnum = QuestRewardReceiveTransaction.TypeEnum || (QuestRewardReceiveTransaction.TypeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    let ForgeThemesEnum;
    (function (ForgeThemesEnum) {
        ForgeThemesEnum["Legacy"] = "legacy";
        ForgeThemesEnum["Spring"] = "spring";
        ForgeThemesEnum["Summer"] = "summer";
        ForgeThemesEnum["Winter"] = "winter";
    })(ForgeThemesEnum = QuestRewardReceiveTransaction.ForgeThemesEnum || (QuestRewardReceiveTransaction.ForgeThemesEnum = {}));
})(QuestRewardReceiveTransaction || (exports.QuestRewardReceiveTransaction = QuestRewardReceiveTransaction = {}));
/**
 * @export
 * @namespace Report
 */
var Report;
(function (Report) {
    /**
     * @export
     * @enum {string}
     */
    let StatusEnum;
    (function (StatusEnum) {
        StatusEnum["Done"] = "done";
        StatusEnum["InProgress"] = "inProgress";
        StatusEnum["New"] = "new";
    })(StatusEnum = Report.StatusEnum || (Report.StatusEnum = {}));
})(Report || (exports.Report = Report = {}));
/**
 * @export
 * @namespace ReportPartial
 */
var ReportPartial;
(function (ReportPartial) {
    /**
     * @export
     * @enum {string}
     */
    let StatusEnum;
    (function (StatusEnum) {
        StatusEnum["Done"] = "done";
        StatusEnum["InProgress"] = "inProgress";
        StatusEnum["New"] = "new";
    })(StatusEnum = ReportPartial.StatusEnum || (ReportPartial.StatusEnum = {}));
})(ReportPartial || (exports.ReportPartial = ReportPartial = {}));
/**
 * @export
 * @namespace ScratchCardUseTransaction
 */
var ScratchCardUseTransaction;
(function (ScratchCardUseTransaction) {
    /**
     * @export
     * @enum {string}
     */
    let TypeEnum;
    (function (TypeEnum) {
        TypeEnum["ScratchCardUse"] = "scratchCardUse";
    })(TypeEnum = ScratchCardUseTransaction.TypeEnum || (ScratchCardUseTransaction.TypeEnum = {}));
})(ScratchCardUseTransaction || (exports.ScratchCardUseTransaction = ScratchCardUseTransaction = {}));
/**
 * @export
 * @namespace ScratchCodeBoosters
 */
var ScratchCodeBoosters;
(function (ScratchCodeBoosters) {
    /**
     * @export
     * @enum {string}
     */
    let EditionEnum;
    (function (EditionEnum) {
        EditionEnum["All"] = "all";
        EditionEnum["Bandits"] = "bandits";
        EditionEnum["Fire"] = "fire";
        EditionEnum["FireFrost"] = "fireFrost";
        EditionEnum["Frost"] = "frost";
        EditionEnum["LostSouls"] = "lostSouls";
        EditionEnum["Mini"] = "mini";
        EditionEnum["Nature"] = "nature";
        EditionEnum["NatureShadow"] = "natureShadow";
        EditionEnum["Rebirth"] = "rebirth";
        EditionEnum["Shadow"] = "shadow";
        EditionEnum["Stonekin"] = "stonekin";
        EditionEnum["Twilight"] = "twilight";
    })(EditionEnum = ScratchCodeBoosters.EditionEnum || (ScratchCodeBoosters.EditionEnum = {}));
})(ScratchCodeBoosters || (exports.ScratchCodeBoosters = ScratchCodeBoosters = {}));
/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
const AccountControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerDeleteById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling accountControllerDeleteById.");
            }
            const localVarPath = `/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "DELETE" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [fForumId]
         * @param {number} [fId]
         * @param {string} [fIdin]
         * @param {string} [fNamestartsWith]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options = {}) {
            const localVarPath = `/accounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fForumId !== undefined) {
                localVarQueryParameter["f.forumId"] = fForumId;
            }
            if (fId !== undefined) {
                localVarQueryParameter["f.id"] = fId;
            }
            if (fIdin !== undefined) {
                localVarQueryParameter["f.id:in"] = fIdin;
            }
            if (fNamestartsWith !== undefined) {
                localVarQueryParameter["f.name:startsWith"] = fNamestartsWith;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter["offset"] = offset;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling accountControllerFindById.");
            }
            const localVarPath = `/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindMy(options = {}) {
            const localVarPath = `/accounts/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {AccountPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerUpdateById(id, body, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling accountControllerUpdateById.");
            }
            const localVarPath = `/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PATCH" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "AccountPartial" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountWipe} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerWipeById(body, id, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling accountControllerWipeById.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling accountControllerWipeById.");
            }
            const localVarPath = `/accounts/{id}/wipe`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "AccountWipe" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.AccountControllerApiFetchParamCreator = AccountControllerApiFetchParamCreator;
/**
 * AccountControllerApi - functional programming interface
 * @export
 */
const AccountControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerDeleteById(id, options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerDeleteById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [fForumId]
         * @param {number} [fId]
         * @param {string} [fIdin]
         * @param {string} [fNamestartsWith]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindMy(options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerFindMy(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {AccountPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerUpdateById(id, body, options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerUpdateById(id, body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AccountWipe} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerWipeById(body, id, options) {
            const localVarFetchArgs = (0, exports.AccountControllerApiFetchParamCreator)(configuration).accountControllerWipeById(body, id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.AccountControllerApiFp = AccountControllerApiFp;
/**
 * AccountControllerApi - factory interface
 * @export
 */
const AccountControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerDeleteById(id, options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerDeleteById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [fForumId]
         * @param {number} [fId]
         * @param {string} [fIdin]
         * @param {string} [fNamestartsWith]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindById(id, options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerFindById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindMy(options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerFindMy(options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {AccountPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerUpdateById(id, body, options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerUpdateById(id, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {AccountWipe} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerWipeById(body, id, options) {
            return (0, exports.AccountControllerApiFp)(configuration).accountControllerWipeById(body, id, options)(fetch, basePath);
        },
    };
};
exports.AccountControllerApiFactory = AccountControllerApiFactory;
/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
class AccountControllerApi extends BaseAPI {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerDeleteById(id, options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerDeleteById(id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} [fForumId]
     * @param {number} [fId]
     * @param {string} [fIdin]
     * @param {string} [fNamestartsWith]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerFind(fForumId, fId, fIdin, fNamestartsWith, limit, offset, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerFindById(id, options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerFindById(id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerFindMy(options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerFindMy(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {AccountPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerUpdateById(id, body, options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerUpdateById(id, body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {AccountWipe} body
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    accountControllerWipeById(body, id, options) {
        return (0, exports.AccountControllerApiFp)(this.configuration).accountControllerWipeById(body, id, options)(this.fetch, this.basePath);
    }
}
exports.AccountControllerApi = AccountControllerApi;
/**
 * AntiCheatLogControllerApi - fetch parameter creator
 * @export
 */
const AntiCheatLogControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options = {}) {
            const localVarPath = `/antiCheatLogs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fAccountId !== undefined) {
                localVarQueryParameter["f.accountId"] = fAccountId;
            }
            if (fTypein) {
                localVarQueryParameter["f.type:in"] = fTypein;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter["offset"] = offset;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.AntiCheatLogControllerApiFetchParamCreator = AntiCheatLogControllerApiFetchParamCreator;
/**
 * AntiCheatLogControllerApi - functional programming interface
 * @export
 */
const AntiCheatLogControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options) {
            const localVarFetchArgs = (0, exports.AntiCheatLogControllerApiFetchParamCreator)(configuration).antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.AntiCheatLogControllerApiFp = AntiCheatLogControllerApiFp;
/**
 * AntiCheatLogControllerApi - factory interface
 * @export
 */
const AntiCheatLogControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options) {
            return (0, exports.AntiCheatLogControllerApiFp)(configuration).antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options)(fetch, basePath);
        },
    };
};
exports.AntiCheatLogControllerApiFactory = AntiCheatLogControllerApiFactory;
/**
 * AntiCheatLogControllerApi - object-oriented interface
 * @export
 * @class AntiCheatLogControllerApi
 * @extends {BaseAPI}
 */
class AntiCheatLogControllerApi extends BaseAPI {
    /**
     *
     * @param {number} [fAccountId]
     * @param {Array<string>} [fTypein]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AntiCheatLogControllerApi
     */
    antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options) {
        return (0, exports.AntiCheatLogControllerApiFp)(this.configuration).antiCheatLogControllerFind(fAccountId, fTypein, limit, offset, sort, options)(this.fetch, this.basePath);
    }
}
exports.AntiCheatLogControllerApi = AntiCheatLogControllerApi;
/**
 * BanControllerApi - fetch parameter creator
 * @export
 */
const BanControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ChangeBanStatus} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banControllerChangeBanStatus(body, options = {}) {
            const localVarPath = `/bans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "ChangeBanStatus" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.BanControllerApiFetchParamCreator = BanControllerApiFetchParamCreator;
/**
 * BanControllerApi - functional programming interface
 * @export
 */
const BanControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ChangeBanStatus} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banControllerChangeBanStatus(body, options) {
            const localVarFetchArgs = (0, exports.BanControllerApiFetchParamCreator)(configuration).banControllerChangeBanStatus(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.BanControllerApiFp = BanControllerApiFp;
/**
 * BanControllerApi - factory interface
 * @export
 */
const BanControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {ChangeBanStatus} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banControllerChangeBanStatus(body, options) {
            return (0, exports.BanControllerApiFp)(configuration).banControllerChangeBanStatus(body, options)(fetch, basePath);
        },
    };
};
exports.BanControllerApiFactory = BanControllerApiFactory;
/**
 * BanControllerApi - object-oriented interface
 * @export
 * @class BanControllerApi
 * @extends {BaseAPI}
 */
class BanControllerApi extends BaseAPI {
    /**
     *
     * @param {ChangeBanStatus} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanControllerApi
     */
    banControllerChangeBanStatus(body, options) {
        return (0, exports.BanControllerApiFp)(this.configuration).banControllerChangeBanStatus(body, options)(this.fetch, this.basePath);
    }
}
exports.BanControllerApi = BanControllerApi;
/**
 * BoosterControllerApi - fetch parameter creator
 * @export
 */
const BoosterControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFind(options = {}) {
            const localVarPath = `/boosters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling boosterControllerFindById.");
            }
            const localVarPath = `/boosters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.BoosterControllerApiFetchParamCreator = BoosterControllerApiFetchParamCreator;
/**
 * BoosterControllerApi - functional programming interface
 * @export
 */
const BoosterControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFind(options) {
            const localVarFetchArgs = (0, exports.BoosterControllerApiFetchParamCreator)(configuration).boosterControllerFind(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.BoosterControllerApiFetchParamCreator)(configuration).boosterControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.BoosterControllerApiFp = BoosterControllerApiFp;
/**
 * BoosterControllerApi - factory interface
 * @export
 */
const BoosterControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFind(options) {
            return (0, exports.BoosterControllerApiFp)(configuration).boosterControllerFind(options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boosterControllerFindById(id, options) {
            return (0, exports.BoosterControllerApiFp)(configuration).boosterControllerFindById(id, options)(fetch, basePath);
        },
    };
};
exports.BoosterControllerApiFactory = BoosterControllerApiFactory;
/**
 * BoosterControllerApi - object-oriented interface
 * @export
 * @class BoosterControllerApi
 * @extends {BaseAPI}
 */
class BoosterControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoosterControllerApi
     */
    boosterControllerFind(options) {
        return (0, exports.BoosterControllerApiFp)(this.configuration).boosterControllerFind(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoosterControllerApi
     */
    boosterControllerFindById(id, options) {
        return (0, exports.BoosterControllerApiFp)(this.configuration).boosterControllerFindById(id, options)(this.fetch, this.basePath);
    }
}
exports.BoosterControllerApi = BoosterControllerApi;
/**
 * CardControllerApi - fetch parameter creator
 * @export
 */
const CardControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFind(options = {}) {
            const localVarPath = `/cards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling cardControllerFindById.");
            }
            const localVarPath = `/cards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.CardControllerApiFetchParamCreator = CardControllerApiFetchParamCreator;
/**
 * CardControllerApi - functional programming interface
 * @export
 */
const CardControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFind(options) {
            const localVarFetchArgs = (0, exports.CardControllerApiFetchParamCreator)(configuration).cardControllerFind(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.CardControllerApiFetchParamCreator)(configuration).cardControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.CardControllerApiFp = CardControllerApiFp;
/**
 * CardControllerApi - factory interface
 * @export
 */
const CardControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFind(options) {
            return (0, exports.CardControllerApiFp)(configuration).cardControllerFind(options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardControllerFindById(id, options) {
            return (0, exports.CardControllerApiFp)(configuration).cardControllerFindById(id, options)(fetch, basePath);
        },
    };
};
exports.CardControllerApiFactory = CardControllerApiFactory;
/**
 * CardControllerApi - object-oriented interface
 * @export
 * @class CardControllerApi
 * @extends {BaseAPI}
 */
class CardControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardControllerApi
     */
    cardControllerFind(options) {
        return (0, exports.CardControllerApiFp)(this.configuration).cardControllerFind(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardControllerApi
     */
    cardControllerFindById(id, options) {
        return (0, exports.CardControllerApiFp)(this.configuration).cardControllerFindById(id, options)(this.fetch, this.basePath);
    }
}
exports.CardControllerApi = CardControllerApi;
/**
 * ChatControllerApi - fetch parameter creator
 * @export
 */
const ChatControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} chatId
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options = {}) {
            // verify required parameter 'chatId' is not null or undefined
            if (chatId === null || chatId === undefined) {
                throw new RequiredError("chatId", "Required parameter chatId was null or undefined when calling chatControllerFindChatMessages.");
            }
            const localVarPath = `/chats/{chatId}`.replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fSendTimege !== undefined) {
                localVarQueryParameter["f.sendTime:ge"] = fSendTimege.toISOString();
            }
            if (fSendTimele !== undefined) {
                localVarQueryParameter["f.sendTime:le"] = fSendTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} [fLastSendTimege]
         * @param {Date} [fLastSendTimele]
         * @param {number} [fParticipantIdshas]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options = {}) {
            const localVarPath = `/chats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fLastSendTimege !== undefined) {
                localVarQueryParameter["f.lastSendTime:ge"] = fLastSendTimege.toISOString();
            }
            if (fLastSendTimele !== undefined) {
                localVarQueryParameter["f.lastSendTime:le"] = fLastSendTimele.toISOString();
            }
            if (fParticipantIdshas !== undefined) {
                localVarQueryParameter["f.participantIds:has"] = fParticipantIdshas;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.ChatControllerApiFetchParamCreator = ChatControllerApiFetchParamCreator;
/**
 * ChatControllerApi - functional programming interface
 * @export
 */
const ChatControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} chatId
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options) {
            const localVarFetchArgs = (0, exports.ChatControllerApiFetchParamCreator)(configuration).chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} [fLastSendTimege]
         * @param {Date} [fLastSendTimele]
         * @param {number} [fParticipantIdshas]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options) {
            const localVarFetchArgs = (0, exports.ChatControllerApiFetchParamCreator)(configuration).chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.ChatControllerApiFp = ChatControllerApiFp;
/**
 * ChatControllerApi - factory interface
 * @export
 */
const ChatControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {string} chatId
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options) {
            return (0, exports.ChatControllerApiFp)(configuration).chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} [fLastSendTimege]
         * @param {Date} [fLastSendTimele]
         * @param {number} [fParticipantIdshas]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options) {
            return (0, exports.ChatControllerApiFp)(configuration).chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options)(fetch, basePath);
        },
    };
};
exports.ChatControllerApiFactory = ChatControllerApiFactory;
/**
 * ChatControllerApi - object-oriented interface
 * @export
 * @class ChatControllerApi
 * @extends {BaseAPI}
 */
class ChatControllerApi extends BaseAPI {
    /**
     *
     * @param {string} chatId
     * @param {Date} [fSendTimege]
     * @param {Date} [fSendTimele]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options) {
        return (0, exports.ChatControllerApiFp)(this.configuration).chatControllerFindChatMessages(chatId, fSendTimege, fSendTimele, limit, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} [fLastSendTimege]
     * @param {Date} [fLastSendTimele]
     * @param {number} [fParticipantIdshas]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options) {
        return (0, exports.ChatControllerApiFp)(this.configuration).chatControllerFindChats(fLastSendTimege, fLastSendTimele, fParticipantIdshas, limit, sort, options)(this.fetch, this.basePath);
    }
}
exports.ChatControllerApi = ChatControllerApi;
/**
 * ConnectionLogControllerApi - fetch parameter creator
 * @export
 */
const ConnectionLogControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {string} [fHwidmatch]
         * @param {string} [fIp]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options = {}) {
            const localVarPath = `/connectionLogs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fAccountId !== undefined) {
                localVarQueryParameter["f.accountId"] = fAccountId;
            }
            if (fHwidmatch !== undefined) {
                localVarQueryParameter["f.hwid:match"] = fHwidmatch;
            }
            if (fIp !== undefined) {
                localVarQueryParameter["f.ip"] = fIp;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter["offset"] = offset;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.ConnectionLogControllerApiFetchParamCreator = ConnectionLogControllerApiFetchParamCreator;
/**
 * ConnectionLogControllerApi - functional programming interface
 * @export
 */
const ConnectionLogControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {string} [fHwidmatch]
         * @param {string} [fIp]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options) {
            const localVarFetchArgs = (0, exports.ConnectionLogControllerApiFetchParamCreator)(configuration).connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.ConnectionLogControllerApiFp = ConnectionLogControllerApiFp;
/**
 * ConnectionLogControllerApi - factory interface
 * @export
 */
const ConnectionLogControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {number} [fAccountId]
         * @param {string} [fHwidmatch]
         * @param {string} [fIp]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options) {
            return (0, exports.ConnectionLogControllerApiFp)(configuration).connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options)(fetch, basePath);
        },
    };
};
exports.ConnectionLogControllerApiFactory = ConnectionLogControllerApiFactory;
/**
 * ConnectionLogControllerApi - object-oriented interface
 * @export
 * @class ConnectionLogControllerApi
 * @extends {BaseAPI}
 */
class ConnectionLogControllerApi extends BaseAPI {
    /**
     *
     * @param {number} [fAccountId]
     * @param {string} [fHwidmatch]
     * @param {string} [fIp]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionLogControllerApi
     */
    connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options) {
        return (0, exports.ConnectionLogControllerApiFp)(this.configuration).connectionLogControllerFind(fAccountId, fHwidmatch, fIp, limit, offset, sort, options)(this.fetch, this.basePath);
    }
}
exports.ConnectionLogControllerApi = ConnectionLogControllerApi;
/**
 * CosmeticControllerApi - fetch parameter creator
 * @export
 */
const CosmeticControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFind(options = {}) {
            const localVarPath = `/cosmetics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling cosmeticControllerFindById.");
            }
            const localVarPath = `/cosmetics/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.CosmeticControllerApiFetchParamCreator = CosmeticControllerApiFetchParamCreator;
/**
 * CosmeticControllerApi - functional programming interface
 * @export
 */
const CosmeticControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFind(options) {
            const localVarFetchArgs = (0, exports.CosmeticControllerApiFetchParamCreator)(configuration).cosmeticControllerFind(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.CosmeticControllerApiFetchParamCreator)(configuration).cosmeticControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.CosmeticControllerApiFp = CosmeticControllerApiFp;
/**
 * CosmeticControllerApi - factory interface
 * @export
 */
const CosmeticControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFind(options) {
            return (0, exports.CosmeticControllerApiFp)(configuration).cosmeticControllerFind(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cosmeticControllerFindById(id, options) {
            return (0, exports.CosmeticControllerApiFp)(configuration).cosmeticControllerFindById(id, options)(fetch, basePath);
        },
    };
};
exports.CosmeticControllerApiFactory = CosmeticControllerApiFactory;
/**
 * CosmeticControllerApi - object-oriented interface
 * @export
 * @class CosmeticControllerApi
 * @extends {BaseAPI}
 */
class CosmeticControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CosmeticControllerApi
     */
    cosmeticControllerFind(options) {
        return (0, exports.CosmeticControllerApiFp)(this.configuration).cosmeticControllerFind(options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CosmeticControllerApi
     */
    cosmeticControllerFindById(id, options) {
        return (0, exports.CosmeticControllerApiFp)(this.configuration).cosmeticControllerFindById(id, options)(this.fetch, this.basePath);
    }
}
exports.CosmeticControllerApi = CosmeticControllerApi;
/**
 * DeckControllerApi - fetch parameter creator
 * @export
 */
const DeckControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} [fOwnerId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFind(fOwnerId, limit, offset, options = {}) {
            const localVarPath = `/decks`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fOwnerId !== undefined) {
                localVarQueryParameter["f.ownerId"] = fOwnerId;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter["offset"] = offset;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deckControllerFindById.");
            }
            const localVarPath = `/decks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {DeckPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerUpdateById(id, body, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deckControllerUpdateById.");
            }
            const localVarPath = `/decks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PATCH" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "DeckPartial" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.DeckControllerApiFetchParamCreator = DeckControllerApiFetchParamCreator;
/**
 * DeckControllerApi - functional programming interface
 * @export
 */
const DeckControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [fOwnerId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFind(fOwnerId, limit, offset, options) {
            const localVarFetchArgs = (0, exports.DeckControllerApiFetchParamCreator)(configuration).deckControllerFind(fOwnerId, limit, offset, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.DeckControllerApiFetchParamCreator)(configuration).deckControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {DeckPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerUpdateById(id, body, options) {
            const localVarFetchArgs = (0, exports.DeckControllerApiFetchParamCreator)(configuration).deckControllerUpdateById(id, body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.DeckControllerApiFp = DeckControllerApiFp;
/**
 * DeckControllerApi - factory interface
 * @export
 */
const DeckControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {number} [fOwnerId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFind(fOwnerId, limit, offset, options) {
            return (0, exports.DeckControllerApiFp)(configuration).deckControllerFind(fOwnerId, limit, offset, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerFindById(id, options) {
            return (0, exports.DeckControllerApiFp)(configuration).deckControllerFindById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {DeckPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deckControllerUpdateById(id, body, options) {
            return (0, exports.DeckControllerApiFp)(configuration).deckControllerUpdateById(id, body, options)(fetch, basePath);
        },
    };
};
exports.DeckControllerApiFactory = DeckControllerApiFactory;
/**
 * DeckControllerApi - object-oriented interface
 * @export
 * @class DeckControllerApi
 * @extends {BaseAPI}
 */
class DeckControllerApi extends BaseAPI {
    /**
     *
     * @param {number} [fOwnerId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeckControllerApi
     */
    deckControllerFind(fOwnerId, limit, offset, options) {
        return (0, exports.DeckControllerApiFp)(this.configuration).deckControllerFind(fOwnerId, limit, offset, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeckControllerApi
     */
    deckControllerFindById(id, options) {
        return (0, exports.DeckControllerApiFp)(this.configuration).deckControllerFindById(id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {DeckPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeckControllerApi
     */
    deckControllerUpdateById(id, body, options) {
        return (0, exports.DeckControllerApiFp)(this.configuration).deckControllerUpdateById(id, body, options)(this.fetch, this.basePath);
    }
}
exports.DeckControllerApi = DeckControllerApi;
/**
 * MailControllerApi - fetch parameter creator
 * @export
 */
const MailControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {number} [fReceiverId]
         * @param {number} [fSenderId]
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {boolean} [fSystem]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options = {}) {
            const localVarPath = `/mails`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fParticipantIdshas) {
                localVarQueryParameter["f.participantIds:has"] = fParticipantIdshas;
            }
            if (fReceiverId !== undefined) {
                localVarQueryParameter["f.receiverId"] = fReceiverId;
            }
            if (fSenderId !== undefined) {
                localVarQueryParameter["f.senderId"] = fSenderId;
            }
            if (fSendTimege !== undefined) {
                localVarQueryParameter["f.sendTime:ge"] = fSendTimege.toISOString();
            }
            if (fSendTimele !== undefined) {
                localVarQueryParameter["f.sendTime:le"] = fSendTimele.toISOString();
            }
            if (fSystem !== undefined) {
                localVarQueryParameter["f.system"] = fSystem;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} mailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerGetMail(mailId, options = {}) {
            // verify required parameter 'mailId' is not null or undefined
            if (mailId === null || mailId === undefined) {
                throw new RequiredError("mailId", "Required parameter mailId was null or undefined when calling mailControllerGetMail.");
            }
            const localVarPath = `/mails/{mailId}`.replace(`{${"mailId"}}`, encodeURIComponent(String(mailId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.MailControllerApiFetchParamCreator = MailControllerApiFetchParamCreator;
/**
 * MailControllerApi - functional programming interface
 * @export
 */
const MailControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {number} [fReceiverId]
         * @param {number} [fSenderId]
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {boolean} [fSystem]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options) {
            const localVarFetchArgs = (0, exports.MailControllerApiFetchParamCreator)(configuration).mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} mailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerGetMail(mailId, options) {
            const localVarFetchArgs = (0, exports.MailControllerApiFetchParamCreator)(configuration).mailControllerGetMail(mailId, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.MailControllerApiFp = MailControllerApiFp;
/**
 * MailControllerApi - factory interface
 * @export
 */
const MailControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {number} [fReceiverId]
         * @param {number} [fSenderId]
         * @param {Date} [fSendTimege]
         * @param {Date} [fSendTimele]
         * @param {boolean} [fSystem]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options) {
            return (0, exports.MailControllerApiFp)(configuration).mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} mailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerGetMail(mailId, options) {
            return (0, exports.MailControllerApiFp)(configuration).mailControllerGetMail(mailId, options)(fetch, basePath);
        },
    };
};
exports.MailControllerApiFactory = MailControllerApiFactory;
/**
 * MailControllerApi - object-oriented interface
 * @export
 * @class MailControllerApi
 * @extends {BaseAPI}
 */
class MailControllerApi extends BaseAPI {
    /**
     *
     * @param {Array<number>} [fParticipantIdshas]
     * @param {number} [fReceiverId]
     * @param {number} [fSenderId]
     * @param {Date} [fSendTimege]
     * @param {Date} [fSendTimele]
     * @param {boolean} [fSystem]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailControllerApi
     */
    mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options) {
        return (0, exports.MailControllerApiFp)(this.configuration).mailControllerFind(fParticipantIdshas, fReceiverId, fSenderId, fSendTimege, fSendTimele, fSystem, limit, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} mailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailControllerApi
     */
    mailControllerGetMail(mailId, options) {
        return (0, exports.MailControllerApiFp)(this.configuration).mailControllerGetMail(mailId, options)(this.fetch, this.basePath);
    }
}
exports.MailControllerApi = MailControllerApi;
/**
 * ReportControllerApi - fetch parameter creator
 * @export
 */
const ReportControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} [fReporteeId]
         * @param {number} [fReporterId]
         * @param {string} [fStatus]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options = {}) {
            const localVarPath = `/reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fReporteeId !== undefined) {
                localVarQueryParameter["f.reporteeId"] = fReporteeId;
            }
            if (fReporterId !== undefined) {
                localVarQueryParameter["f.reporterId"] = fReporterId;
            }
            if (fStatus !== undefined) {
                localVarQueryParameter["f.status"] = fStatus;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter["offset"] = offset;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling reportControllerFindById.");
            }
            const localVarPath = `/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {ReportPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerUpdateById(id, body, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling reportControllerUpdateById.");
            }
            const localVarPath = `/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PATCH" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "ReportPartial" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.ReportControllerApiFetchParamCreator = ReportControllerApiFetchParamCreator;
/**
 * ReportControllerApi - functional programming interface
 * @export
 */
const ReportControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} [fReporteeId]
         * @param {number} [fReporterId]
         * @param {string} [fStatus]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options) {
            const localVarFetchArgs = (0, exports.ReportControllerApiFetchParamCreator)(configuration).reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.ReportControllerApiFetchParamCreator)(configuration).reportControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {ReportPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerUpdateById(id, body, options) {
            const localVarFetchArgs = (0, exports.ReportControllerApiFetchParamCreator)(configuration).reportControllerUpdateById(id, body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.ReportControllerApiFp = ReportControllerApiFp;
/**
 * ReportControllerApi - factory interface
 * @export
 */
const ReportControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {number} [fReporteeId]
         * @param {number} [fReporterId]
         * @param {string} [fStatus]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options) {
            return (0, exports.ReportControllerApiFp)(configuration).reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerFindById(id, options) {
            return (0, exports.ReportControllerApiFp)(configuration).reportControllerFindById(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {ReportPartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerUpdateById(id, body, options) {
            return (0, exports.ReportControllerApiFp)(configuration).reportControllerUpdateById(id, body, options)(fetch, basePath);
        },
    };
};
exports.ReportControllerApiFactory = ReportControllerApiFactory;
/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
class ReportControllerApi extends BaseAPI {
    /**
     *
     * @param {number} [fReporteeId]
     * @param {number} [fReporterId]
     * @param {string} [fStatus]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options) {
        return (0, exports.ReportControllerApiFp)(this.configuration).reportControllerFind(fReporteeId, fReporterId, fStatus, limit, offset, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    reportControllerFindById(id, options) {
        return (0, exports.ReportControllerApiFp)(this.configuration).reportControllerFindById(id, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {ReportPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    reportControllerUpdateById(id, body, options) {
        return (0, exports.ReportControllerApiFp)(this.configuration).reportControllerUpdateById(id, body, options)(this.fetch, this.basePath);
    }
}
exports.ReportControllerApi = ReportControllerApi;
/**
 * ScratchCodeControllerApi - fetch parameter creator
 * @export
 */
const ScratchCodeControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateScratchCode} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreate(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling scratchCodeControllerCreate.");
            }
            const localVarPath = `/scratchCodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "CreateScratchCode" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateScratchCodeGroup} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreateGroup(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling scratchCodeControllerCreateGroup.");
            }
            const localVarPath = `/scratchCodes/groups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "CreateScratchCodeGroup" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} [fAvailableFromge]
         * @param {Date} [fAvailableFromle]
         * @param {string} [fGroupIdin]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options = {}) {
            const localVarPath = `/scratchCodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fAvailableFromge !== undefined) {
                localVarQueryParameter["f.availableFrom:ge"] = fAvailableFromge.toISOString();
            }
            if (fAvailableFromle !== undefined) {
                localVarQueryParameter["f.availableFrom:le"] = fAvailableFromle.toISOString();
            }
            if (fGroupIdin !== undefined) {
                localVarQueryParameter["f.groupId:in"] = fGroupIdin;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} [fCreationTimege]
         * @param {Date} [fCreationTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options = {}) {
            const localVarPath = `/scratchCodes/groups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fCreationTimege !== undefined) {
                localVarQueryParameter["f.creationTime:ge"] = fCreationTimege.toISOString();
            }
            if (fCreationTimele !== undefined) {
                localVarQueryParameter["f.creationTime:le"] = fCreationTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {ScratchCodePartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerUpdateById(id, body, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling scratchCodeControllerUpdateById.");
            }
            const localVarPath = `/scratchCodes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "PATCH" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "ScratchCodePartial" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.ScratchCodeControllerApiFetchParamCreator = ScratchCodeControllerApiFetchParamCreator;
/**
 * ScratchCodeControllerApi - functional programming interface
 * @export
 */
const ScratchCodeControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateScratchCode} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreate(body, options) {
            const localVarFetchArgs = (0, exports.ScratchCodeControllerApiFetchParamCreator)(configuration).scratchCodeControllerCreate(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {CreateScratchCodeGroup} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreateGroup(body, options) {
            const localVarFetchArgs = (0, exports.ScratchCodeControllerApiFetchParamCreator)(configuration).scratchCodeControllerCreateGroup(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} [fAvailableFromge]
         * @param {Date} [fAvailableFromle]
         * @param {string} [fGroupIdin]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options) {
            const localVarFetchArgs = (0, exports.ScratchCodeControllerApiFetchParamCreator)(configuration).scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} [fCreationTimege]
         * @param {Date} [fCreationTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options) {
            const localVarFetchArgs = (0, exports.ScratchCodeControllerApiFetchParamCreator)(configuration).scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {ScratchCodePartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerUpdateById(id, body, options) {
            const localVarFetchArgs = (0, exports.ScratchCodeControllerApiFetchParamCreator)(configuration).scratchCodeControllerUpdateById(id, body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.ScratchCodeControllerApiFp = ScratchCodeControllerApiFp;
/**
 * ScratchCodeControllerApi - factory interface
 * @export
 */
const ScratchCodeControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {CreateScratchCode} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreate(body, options) {
            return (0, exports.ScratchCodeControllerApiFp)(configuration).scratchCodeControllerCreate(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {CreateScratchCodeGroup} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerCreateGroup(body, options) {
            return (0, exports.ScratchCodeControllerApiFp)(configuration).scratchCodeControllerCreateGroup(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} [fAvailableFromge]
         * @param {Date} [fAvailableFromle]
         * @param {string} [fGroupIdin]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options) {
            return (0, exports.ScratchCodeControllerApiFp)(configuration).scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} [fCreationTimege]
         * @param {Date} [fCreationTimele]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options) {
            return (0, exports.ScratchCodeControllerApiFp)(configuration).scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {ScratchCodePartial} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scratchCodeControllerUpdateById(id, body, options) {
            return (0, exports.ScratchCodeControllerApiFp)(configuration).scratchCodeControllerUpdateById(id, body, options)(fetch, basePath);
        },
    };
};
exports.ScratchCodeControllerApiFactory = ScratchCodeControllerApiFactory;
/**
 * ScratchCodeControllerApi - object-oriented interface
 * @export
 * @class ScratchCodeControllerApi
 * @extends {BaseAPI}
 */
class ScratchCodeControllerApi extends BaseAPI {
    /**
     *
     * @param {CreateScratchCode} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScratchCodeControllerApi
     */
    scratchCodeControllerCreate(body, options) {
        return (0, exports.ScratchCodeControllerApiFp)(this.configuration).scratchCodeControllerCreate(body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {CreateScratchCodeGroup} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScratchCodeControllerApi
     */
    scratchCodeControllerCreateGroup(body, options) {
        return (0, exports.ScratchCodeControllerApiFp)(this.configuration).scratchCodeControllerCreateGroup(body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} [fAvailableFromge]
     * @param {Date} [fAvailableFromle]
     * @param {string} [fGroupIdin]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScratchCodeControllerApi
     */
    scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options) {
        return (0, exports.ScratchCodeControllerApiFp)(this.configuration).scratchCodeControllerFind(fAvailableFromge, fAvailableFromle, fGroupIdin, limit, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} [fCreationTimege]
     * @param {Date} [fCreationTimele]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScratchCodeControllerApi
     */
    scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options) {
        return (0, exports.ScratchCodeControllerApiFp)(this.configuration).scratchCodeControllerFindGroups(fCreationTimege, fCreationTimele, limit, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {ScratchCodePartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScratchCodeControllerApi
     */
    scratchCodeControllerUpdateById(id, body, options) {
        return (0, exports.ScratchCodeControllerApiFp)(this.configuration).scratchCodeControllerUpdateById(id, body, options)(this.fetch, this.basePath);
    }
}
exports.ScratchCodeControllerApi = ScratchCodeControllerApi;
/**
 * StatControllerApi - fetch parameter creator
 * @export
 */
const StatControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostReported(fTimege, fTimele, limit, options = {}) {
            // verify required parameter 'fTimege' is not null or undefined
            if (fTimege === null || fTimege === undefined) {
                throw new RequiredError("fTimege", "Required parameter fTimege was null or undefined when calling statControllerFindMostReported.");
            }
            const localVarPath = `/stat/mostReported`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fTimege !== undefined) {
                localVarQueryParameter["f.time:ge"] = fTimege.toISOString();
            }
            if (fTimele !== undefined) {
                localVarQueryParameter["f.time:le"] = fTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options = {}) {
            // verify required parameter 'fTimege' is not null or undefined
            if (fTimege === null || fTimege === undefined) {
                throw new RequiredError("fTimege", "Required parameter fTimege was null or undefined when calling statControllerFindMostUnbalancedTrades.");
            }
            const localVarPath = `/stat/mostUnbalancedTrades`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fTimege !== undefined) {
                localVarQueryParameter["f.time:ge"] = fTimege.toISOString();
            }
            if (fTimele !== undefined) {
                localVarQueryParameter["f.time:le"] = fTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options = {}) {
            // verify required parameter 'fTimege' is not null or undefined
            if (fTimege === null || fTimege === undefined) {
                throw new RequiredError("fTimege", "Required parameter fTimege was null or undefined when calling statControllerFindMostUnbalancedTradesAggregated.");
            }
            const localVarPath = `/stat/mostUnbalancedTradesAggregated`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fTimege !== undefined) {
                localVarQueryParameter["f.time:ge"] = fTimege.toISOString();
            }
            if (fTimele !== undefined) {
                localVarQueryParameter["f.time:le"] = fTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMultiUserSystems(fTimege, fTimele, limit, options = {}) {
            // verify required parameter 'fTimege' is not null or undefined
            if (fTimege === null || fTimege === undefined) {
                throw new RequiredError("fTimege", "Required parameter fTimege was null or undefined when calling statControllerFindMultiUserSystems.");
            }
            const localVarPath = `/stat/multiUserSystems`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fTimege !== undefined) {
                localVarQueryParameter["f.time:ge"] = fTimege.toISOString();
            }
            if (fTimele !== undefined) {
                localVarQueryParameter["f.time:le"] = fTimele.toISOString();
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.StatControllerApiFetchParamCreator = StatControllerApiFetchParamCreator;
/**
 * StatControllerApi - functional programming interface
 * @export
 */
const StatControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostReported(fTimege, fTimele, limit, options) {
            const localVarFetchArgs = (0, exports.StatControllerApiFetchParamCreator)(configuration).statControllerFindMostReported(fTimege, fTimele, limit, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options) {
            const localVarFetchArgs = (0, exports.StatControllerApiFetchParamCreator)(configuration).statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options) {
            const localVarFetchArgs = (0, exports.StatControllerApiFetchParamCreator)(configuration).statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMultiUserSystems(fTimege, fTimele, limit, options) {
            const localVarFetchArgs = (0, exports.StatControllerApiFetchParamCreator)(configuration).statControllerFindMultiUserSystems(fTimege, fTimele, limit, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.StatControllerApiFp = StatControllerApiFp;
/**
 * StatControllerApi - factory interface
 * @export
 */
const StatControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostReported(fTimege, fTimele, limit, options) {
            return (0, exports.StatControllerApiFp)(configuration).statControllerFindMostReported(fTimege, fTimele, limit, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options) {
            return (0, exports.StatControllerApiFp)(configuration).statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options) {
            return (0, exports.StatControllerApiFp)(configuration).statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options)(fetch, basePath);
        },
        /**
         *
         * @param {Date} fTimege
         * @param {Date} [fTimele]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerFindMultiUserSystems(fTimege, fTimele, limit, options) {
            return (0, exports.StatControllerApiFp)(configuration).statControllerFindMultiUserSystems(fTimege, fTimele, limit, options)(fetch, basePath);
        },
    };
};
exports.StatControllerApiFactory = StatControllerApiFactory;
/**
 * StatControllerApi - object-oriented interface
 * @export
 * @class StatControllerApi
 * @extends {BaseAPI}
 */
class StatControllerApi extends BaseAPI {
    /**
     *
     * @param {Date} fTimege
     * @param {Date} [fTimele]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    statControllerFindMostReported(fTimege, fTimele, limit, options) {
        return (0, exports.StatControllerApiFp)(this.configuration).statControllerFindMostReported(fTimege, fTimele, limit, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} fTimege
     * @param {Date} [fTimele]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options) {
        return (0, exports.StatControllerApiFp)(this.configuration).statControllerFindMostUnbalancedTrades(fTimege, fTimele, limit, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} fTimege
     * @param {Date} [fTimele]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options) {
        return (0, exports.StatControllerApiFp)(this.configuration).statControllerFindMostUnbalancedTradesAggregated(fTimege, fTimele, limit, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {Date} fTimege
     * @param {Date} [fTimele]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatControllerApi
     */
    statControllerFindMultiUserSystems(fTimege, fTimele, limit, options) {
        return (0, exports.StatControllerApiFp)(this.configuration).statControllerFindMultiUserSystems(fTimege, fTimele, limit, options)(this.fetch, this.basePath);
    }
}
exports.StatControllerApi = StatControllerApi;
/**
 * TransactionControllerApi - fetch parameter creator
 * @export
 */
const TransactionControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {Date} [fTimeOfTransactionge]
         * @param {Date} [fTimeOfTransactionle]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options = {}) {
            const localVarPath = `/transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            if (fParticipantIdshas) {
                localVarQueryParameter["f.participantIds:has"] = fParticipantIdshas;
            }
            if (fTimeOfTransactionge !== undefined) {
                localVarQueryParameter["f.timeOfTransaction:ge"] = fTimeOfTransactionge.toISOString();
            }
            if (fTimeOfTransactionle !== undefined) {
                localVarQueryParameter["f.timeOfTransaction:le"] = fTimeOfTransactionle.toISOString();
            }
            if (fTypein) {
                localVarQueryParameter["f.type:in"] = fTypein;
            }
            if (limit !== undefined) {
                localVarQueryParameter["limit"] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter["sort"] = sort;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFindById(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling transactionControllerFindById.");
            }
            const localVarPath = `/transactions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.TransactionControllerApiFetchParamCreator = TransactionControllerApiFetchParamCreator;
/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
const TransactionControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {Date} [fTimeOfTransactionge]
         * @param {Date} [fTimeOfTransactionle]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options) {
            const localVarFetchArgs = (0, exports.TransactionControllerApiFetchParamCreator)(configuration).transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFindById(id, options) {
            const localVarFetchArgs = (0, exports.TransactionControllerApiFetchParamCreator)(configuration).transactionControllerFindById(id, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.TransactionControllerApiFp = TransactionControllerApiFp;
/**
 * TransactionControllerApi - factory interface
 * @export
 */
const TransactionControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {Array<number>} [fParticipantIdshas]
         * @param {Date} [fTimeOfTransactionge]
         * @param {Date} [fTimeOfTransactionle]
         * @param {Array<string>} [fTypein]
         * @param {number} [limit]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options) {
            return (0, exports.TransactionControllerApiFp)(configuration).transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerFindById(id, options) {
            return (0, exports.TransactionControllerApiFp)(configuration).transactionControllerFindById(id, options)(fetch, basePath);
        },
    };
};
exports.TransactionControllerApiFactory = TransactionControllerApiFactory;
/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
class TransactionControllerApi extends BaseAPI {
    /**
     *
     * @param {Array<number>} [fParticipantIdshas]
     * @param {Date} [fTimeOfTransactionge]
     * @param {Date} [fTimeOfTransactionle]
     * @param {Array<string>} [fTypein]
     * @param {number} [limit]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options) {
        return (0, exports.TransactionControllerApiFp)(this.configuration).transactionControllerFind(fParticipantIdshas, fTimeOfTransactionge, fTimeOfTransactionle, fTypein, limit, sort, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    transactionControllerFindById(id, options) {
        return (0, exports.TransactionControllerApiFp)(this.configuration).transactionControllerFindById(id, options)(this.fetch, this.basePath);
    }
}
exports.TransactionControllerApi = TransactionControllerApi;
/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
const UserControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {LoginBody} body The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLogin(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling userControllerLogin.");
            }
            const localVarPath = `/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "POST" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = "LoginBody" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerWhoAmI(options = {}) {
            const localVarPath = `/whoAmI`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: "GET" }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.UserControllerApiFetchParamCreator = UserControllerApiFetchParamCreator;
/**
 * UserControllerApi - functional programming interface
 * @export
 */
const UserControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @param {LoginBody} body The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLogin(body, options) {
            const localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).userControllerLogin(body, options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerWhoAmI(options) {
            const localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).userControllerWhoAmI(options);
            return (fetch = isomorphicFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.UserControllerApiFp = UserControllerApiFp;
/**
 * UserControllerApi - factory interface
 * @export
 */
const UserControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {LoginBody} body The input of login function
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLogin(body, options) {
            return (0, exports.UserControllerApiFp)(configuration).userControllerLogin(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerWhoAmI(options) {
            return (0, exports.UserControllerApiFp)(configuration).userControllerWhoAmI(options)(fetch, basePath);
        },
    };
};
exports.UserControllerApiFactory = UserControllerApiFactory;
/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
class UserControllerApi extends BaseAPI {
    /**
     *
     * @param {LoginBody} body The input of login function
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    userControllerLogin(body, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).userControllerLogin(body, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    userControllerWhoAmI(options) {
        return (0, exports.UserControllerApiFp)(this.configuration).userControllerWhoAmI(options)(this.fetch, this.basePath);
    }
}
exports.UserControllerApi = UserControllerApi;

// eslint-disable-next-line no-restricted-imports
import {
  AccountControllerApi,
  AntiCheatLogControllerApi,
  BanControllerApi,
  BoosterControllerApi,
  CardControllerApi,
  ChatControllerApi,
  Configuration,
  ConnectionLogControllerApi,
  CosmeticControllerApi,
  DeckControllerApi,
  MailControllerApi,
  ReportControllerApi,
  ScratchCodeControllerApi,
  StatControllerApi,
  TransactionControllerApi,
  UserControllerApi,
  type FetchAPI,
} from "@skylords/api-client";
import DataLoader from "dataloader";
import { LRUMap } from "lru_map";
import { terminateSession } from "../lib/sessionManagement";
import type { Account } from "./Account";
import type { AntiCheatLog, AntiCheatLogType } from "./AntiCheatLog";
import type { Booster } from "./Booster";
import type { Card } from "./Card";
import type { ChangeBanStatus } from "./ChangeBanStatus";
import type { Chat } from "./Chat";
import type { ChatMessage } from "./ChatMessage";
import type { ConnectionLog } from "./ConnectionLog";
import type { Cosmetic } from "./Cosmetic";
import type { CreateScratchCode } from "./CreateScratchCode";
import type { CreateScratchCodeGroup } from "./CreateScratchCodeGroup";
import type { Deck } from "./Deck";
import type { Mail } from "./Mail";
import type { Report, ReportStatus } from "./Report";
import type { StatMultiUserSystem } from "./StatMultiUserSystem";
import type { StatReported } from "./StatReported";
import type { Transaction, TransactionType } from "./Transaction";
import type { StatMostUnbalancedTrades } from "./StatMostUnbalancedTrades";
import type { ScratchCode } from "./ScratchCode";
import type { ScratchCodeGroup } from "./ScratchCodeGroup";
import type { StatMostUnbalancedTradesAggregated } from "./StatMostUnbalancedTradesAggregated";

/**
 * This function is used to fix up Dates in the API objects.
 *
 * While our API generator generates the proper TS typings for Dates
 * returned by the backend, it does not deserialize them.
 * We use this function to fix that.
 *
 * It takes the object to be fixed as the first parameter and a list
 * of properties to fix as the second.
 *
 * While the typings of this function prevent the user from adding
 * the wrong properties, they do not enforce that all have been passed.
 *
 * We hope this will get fixed in a future version of the generator
 * and this will no longer be needed. The function is written in a
 * way to not break, when the error gets fixed upstream.
 *
 * This function is not needed for Date objects being *send to*
 * the backend only for objects *received by* it.
 */
function fixDate<T>(
  object: T,
  dateProps: {
    [K in keyof T]: Date extends T[K] ? K : never;
  }[keyof T][],
): T {
  const clone = { ...object };

  for (const key of dateProps) {
    const value = clone[key] as unknown as string | Date | undefined | null;

    if (typeof value === "string") {
      clone[key] = new Date(value) as any;
    }
  }

  return clone;
}

interface ApiOptions {
  readonly apiKey?: string;
  readonly basePath: string;
}

interface GetAccountsFilters {
  readonly forumId?: number;
  readonly id?: number;
  readonly idIn?: number[];
  readonly nameStartsWith?: string;
}

interface GetAccountsOptions {
  readonly filters?: GetAccountsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["name", "asc" | "desc" | undefined][];
}

interface GetAntiCheatLogsFilters {
  readonly accountId?: number;
  readonly typeIn?: AntiCheatLogType[];
}

interface GetAntiCheatLogsOptions {
  readonly filters?: GetAntiCheatLogsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["detectionTime", "asc" | "desc" | undefined][];
}

interface GetChatsFilters {
  readonly lastSendTimeGe?: Date;
  readonly lastSendTimeLe?: Date;
  readonly participantIdsHas?: number;
}

interface GetChatsOptions {
  readonly filters?: GetChatsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["lastSendTime", "asc" | "desc" | undefined][];
}

interface GetChatMessagesFilters {
  readonly sendTimeGe?: Date;
  readonly sendTimeLe?: Date;
}

interface GetChatMessagesOptions {
  readonly chatId: string;
  readonly filters?: GetChatMessagesFilters;
  readonly limit?: number;
  readonly sort?: ["sendTime", "asc" | "desc" | undefined][];
}

interface GetConnectionLogsFilters {
  readonly accountId?: number;
  readonly hwidMatch?: string;
  readonly ip?: string;
}

interface GetConnectionLogsOptions {
  readonly filters?: GetConnectionLogsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["lastUsed", "asc" | "desc" | undefined][];
}

interface GetDecksFilters {
  readonly ownerId?: number;
}

interface GetDecksOptions {
  readonly filters?: GetDecksFilters;
  readonly limit?: number;
  readonly offset?: number;
}

interface GetMailOptions {
  readonly mailId: number;
}

interface GetMailsFilters {
  readonly receiverId?: number;
  readonly senderId?: number;
  readonly sendTimeGe?: Date;
  readonly sendTimeLe?: Date;
  readonly system?: boolean;
  readonly participantIdsHas?: number[];
}

interface GetMailsOptions {
  readonly filters?: GetMailsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["sendTime", "asc" | "desc" | undefined][];
}

interface GetReportsFilters {
  readonly reporteeId?: number;
  readonly reporterId?: number;
  readonly status?: ReportStatus;
}

interface GetReportsOptions {
  readonly filters?: GetReportsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["reportTime", "asc" | "desc" | undefined][];
}

interface GetScratchCodesFilters {
  readonly availableFromGe?: Date;
  readonly availableFromLe?: Date;
  readonly groupIdIn?: number[];
}

interface GetScratchCodesOptions {
  readonly filters?: GetScratchCodesFilters;
  readonly limit?: number;
  readonly sort?: readonly ["availableFrom", "asc" | "desc" | undefined][];
}

interface GetScratchCodeGroupsFilters {
  readonly creationTimeGe?: Date;
  readonly creationTimeLe?: Date;
}

interface GetScratchCodeGroupsOptions {
  readonly filters?: GetScratchCodeGroupsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["creationTime", "asc" | "desc" | undefined][];
}

interface GetStatFilters {
  readonly timeGe: Date;
  readonly timeLe?: Date;
}

interface GetStatOptions {
  readonly filters: GetStatFilters;
  readonly limit?: number;
}

interface GetTransactionsFilters {
  readonly participantIdsHas?: [number] | [number, number | undefined];
  readonly timeOfTransactionGe?: Date;
  readonly timeOfTransactionLe?: Date;
  readonly typeIn?: TransactionType[];
}

interface GetTransactionsOptions {
  readonly filters?: GetTransactionsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["timeOfTransaction", "asc" | "desc" | undefined][];
}

interface UpdateAccountOptions {
  readonly id: number;
  readonly name?: string;
}

interface UpdateDeckOptions {
  readonly id: number;
  readonly name?: string;
}

interface UpdateReportOptions {
  readonly description?: string;
  readonly id: number;
  readonly status?: ReportStatus;
}

interface UpdateScratchCodeOptions {
  readonly availableFrom?: Date;
  readonly availableTo?: Date;
  readonly id: number;
  readonly remainingUses?: number;
}

interface WipeAccountOptions {
  readonly id: number;
  readonly reason: string;
}

export class Api {
  readonly #accountController: AccountControllerApi;
  readonly #antiCheatLogController: AntiCheatLogControllerApi;
  readonly #banController: BanControllerApi;
  readonly #boosterController: BoosterControllerApi;
  readonly #cardController: CardControllerApi;
  readonly #chatController: ChatControllerApi;
  readonly #connectionLogController: ConnectionLogControllerApi;
  readonly #cosmeticController: CosmeticControllerApi;
  readonly #deckController: DeckControllerApi;
  readonly #mailController: MailControllerApi;
  readonly #reportController: ReportControllerApi;
  readonly #scratchCodeController: ScratchCodeControllerApi;
  readonly #statController: StatControllerApi;
  readonly #transactionController: TransactionControllerApi;
  readonly #userController: UserControllerApi;

  constructor(options: ApiOptions) {
    const { apiKey, basePath } = options;

    const configuration = new Configuration({
      basePath,
    });

    const myFetch: FetchAPI = async (url, options) => {
      const response = await fetch(
        url,
        typeof apiKey === "undefined"
          ? options
          : {
              ...options,
              headers: {
                ...options.headers,
                Authorization: `Bearer ${apiKey}`,
              },
            },
      );

      if (response.status === 401) {
        terminateSession();
      }

      return response;
    };

    this.#accountController = new AccountControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#antiCheatLogController = new AntiCheatLogControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#banController = new BanControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#boosterController = new BoosterControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#cardController = new CardControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#chatController = new ChatControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#connectionLogController = new ConnectionLogControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#cosmeticController = new CosmeticControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#deckController = new DeckControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#mailController = new MailControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#reportController = new ReportControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#scratchCodeController = new ScratchCodeControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#statController = new StatControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#transactionController = new TransactionControllerApi(
      configuration,
      undefined,
      myFetch,
    );
    this.#userController = new UserControllerApi(
      configuration,
      undefined,
      myFetch,
    );
  }

  async changeBanStatus(changeBanStatus: ChangeBanStatus): Promise<void> {
    await this.#banController.banControllerChangeBanStatus(changeBanStatus);
  }

  async createScratchCode(scratchCode: CreateScratchCode): Promise<void> {
    await this.#scratchCodeController.scratchCodeControllerCreate(scratchCode);
  }

  async createScratchCodeGroup(
    scratchCodeGroup: CreateScratchCodeGroup,
  ): Promise<void> {
    await this.#scratchCodeController.scratchCodeControllerCreateGroup(
      scratchCodeGroup,
    );
  }

  async deleteAccountById(id: number): Promise<void> {
    await this.#accountController.accountControllerDeleteById(id);
  }

  readonly #accountLoader = new DataLoader(
    async (ids: readonly number[]) => {
      const brokenAccounts =
        await this.#accountController.accountControllerFind(
          undefined,
          undefined,
          ids.join(","),
          undefined,
          undefined,
          undefined,
          undefined,
        );

      const accounts = brokenAccounts.map((brokenAccount) =>
        fixDate(brokenAccount, [
          "lastBoughtCheaperBooster",
          "lastFreePvpDecksReroll",
          "lastOnline",
          "lastQuestGet",
          "lastQuestReroll",
          "lastSelectedDailyQuestPoolChange",
        ]),
      );

      return ids.map((id) => {
        const account = accounts.find((v) => v.id === id);

        if (typeof account === "undefined") {
          return new Error(`Account with ID ${id} not found!`);
        } else {
          return account;
        }
      });
    },
    {
      batchScheduleFn: (callback) => setTimeout(callback, 128),
      cacheMap: new LRUMap(4_096),
      maxBatchSize: 128,
    },
  );

  async getAccountById(id: number): Promise<Account> {
    const brokenAccount =
      await this.#accountController.accountControllerFindById(id);
    const account = fixDate(brokenAccount, [
      "lastBoughtCheaperBooster",
      "lastFreePvpDecksReroll",
      "lastOnline",
      "lastQuestGet",
      "lastQuestReroll",
      "lastSelectedDailyQuestPoolChange",
    ]);

    this.#accountLoader.clear(id);
    this.#accountLoader.prime(id, account);

    return account;
  }

  async getCachedAccountById(id: number): Promise<Account> {
    return this.#accountLoader.load(id);
  }

  async getAccounts(options?: GetAccountsOptions): Promise<Account[]> {
    const { filters, limit, offset, sort } = options ?? {};
    const { forumId, id, idIn, nameStartsWith } = filters ?? {};

    const brokenAccounts = await this.#accountController.accountControllerFind(
      forumId,
      id,
      idIn?.join(","),
      nameStartsWith,
      limit,
      offset,
      sort?.map((v) => v.join(":")).join(","),
    );
    const accounts = brokenAccounts.map((brokenAccount) =>
      fixDate(brokenAccount, [
        "lastBoughtCheaperBooster",
        "lastFreePvpDecksReroll",
        "lastOnline",
        "lastQuestGet",
        "lastQuestReroll",
        "lastSelectedDailyQuestPoolChange",
      ]),
    );

    for (const account of accounts) {
      this.#accountLoader.clear(account.id);
      this.#accountLoader.prime(account.id, account);
    }

    return accounts;
  }

  async getAntiCheatLogs(
    options: GetAntiCheatLogsOptions,
  ): Promise<AntiCheatLog[]> {
    const { filters, limit, offset, sort } = options;
    const { accountId, typeIn } = filters ?? {};

    const antiCheatLogsWithoutRelations =
      await this.#antiCheatLogController.antiCheatLogControllerFind(
        accountId,
        typeIn as string[] | undefined,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      antiCheatLogsWithoutRelations.map(async (brokenAntiCheatLog) => {
        const antiCheatLog = fixDate(brokenAntiCheatLog, ["detectionTime"]);
        const account = await this.getCachedAccountById(antiCheatLog.accountId);

        return {
          ...antiCheatLog,
          account,
        };
      }),
    );
  }

  #cachedBoosters: Promise<Booster[]> | undefined;

  async getBoosters(): Promise<Booster[]> {
    const boosters = this.#boosterController.boosterControllerFind();

    if (typeof this.#cachedBoosters === "undefined") {
      this.#cachedBoosters = boosters;
    }

    return boosters;
  }

  async getCachedBoosters(): Promise<Booster[]> {
    const cachedBooster = this.#cachedBoosters;

    if (typeof cachedBooster === "undefined") {
      return this.getBoosters();
    } else {
      return cachedBooster;
    }
  }

  #cachedCards: Promise<Card[]> | undefined;

  async getCards(): Promise<Card[]> {
    const cards = this.#cardController.cardControllerFind();

    if (typeof this.#cachedCards === "undefined") {
      this.#cachedCards = cards;
    }

    return cards;
  }

  async getCachedCards(): Promise<Card[]> {
    const cachedCards = this.#cachedCards;

    if (typeof cachedCards === "undefined") {
      return this.getCards();
    } else {
      return cachedCards;
    }
  }

  async getChats(options: GetChatsOptions): Promise<Chat[]> {
    const { filters, limit, sort } = options;
    const { lastSendTimeGe, lastSendTimeLe, participantIdsHas } = filters ?? {};

    const brokenChats = await this.#chatController.chatControllerFindChats(
      lastSendTimeGe,
      lastSendTimeLe,
      participantIdsHas,
      limit,
      sort?.map((v) => v.join(":")).join(","),
    );

    const chats = brokenChats.map((brokenChat) =>
      fixDate(brokenChat, ["lastSendTime"]),
    );

    return chats;
  }

  async getChatMessages(
    options: GetChatMessagesOptions,
  ): Promise<ChatMessage[]> {
    const { chatId, filters, limit, sort } = options;
    const { sendTimeGe, sendTimeLe } = filters ?? {};

    const chatsWithoutRelations =
      await this.#chatController.chatControllerFindChatMessages(
        chatId,
        sendTimeGe,
        sendTimeLe,
        limit,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      chatsWithoutRelations.map(async (brokenChatMessage) => {
        const chatMessage = fixDate(brokenChatMessage, ["sendTime"]);

        return {
          ...chatMessage,
          sender: await this.getCachedAccountById(chatMessage.senderId),
        };
      }),
    );
  }

  async getConnectionLogs(
    options: GetConnectionLogsOptions,
  ): Promise<ConnectionLog[]> {
    const { filters, limit, offset, sort } = options;
    const { accountId, hwidMatch, ip } = filters ?? {};

    const connectionLogsWithoutRelations =
      await this.#connectionLogController.connectionLogControllerFind(
        accountId,
        hwidMatch,
        ip,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      connectionLogsWithoutRelations.map(async (brokenConnectionLog) => {
        const connectionLog = fixDate(brokenConnectionLog, [
          "firstUsed",
          "lastUsed",
        ]);
        const account = await this.getCachedAccountById(
          connectionLog.accountId,
        );

        return {
          ...connectionLog,
          account,
        };
      }),
    );
  }

  #cachedCosmetics: Promise<Cosmetic[]> | undefined;

  async getCosmetics(): Promise<Cosmetic[]> {
    const cosmetics = this.#cosmeticController
      .cosmeticControllerFind()
      .then((cosmetics) => cosmetics.map((v) => fixDate(v, ["creationDate"])));

    if (typeof this.#cachedCosmetics === "undefined") {
      this.#cachedCosmetics = cosmetics;
    }

    return cosmetics;
  }

  async getCachedCosmetics(): Promise<Cosmetic[]> {
    const cachedCosmetics = this.#cachedCosmetics;

    if (typeof cachedCosmetics === "undefined") {
      return this.getCosmetics();
    } else {
      return cachedCosmetics;
    }
  }

  async getDeckById(id: number): Promise<Deck> {
    return await this.#deckController.deckControllerFindById(id);
  }

  async getDecks(options?: GetDecksOptions): Promise<Deck[]> {
    const { filters, limit, offset } = options ?? {};
    const { ownerId } = filters ?? {};

    return await this.#deckController.deckControllerFind(
      ownerId,
      limit,
      offset,
    );
  }

  async getMail(options: GetMailOptions): Promise<Mail> {
    const { mailId } = options;

    const brokenMail = await this.#mailController.mailControllerGetMail(mailId);

    const mail = fixDate(brokenMail, [
      "collectionTime",
      "deletionTime",
      "sendTime",
    ]);

    const [receiver, sender] = await Promise.all([
      this.getCachedAccountById(mail.receiverId),
      this.getCachedAccountById(mail.senderId),
    ]);

    return {
      ...mail,
      receiver,
      sender,
    };
  }

  async getMails(options: GetMailsOptions): Promise<Mail[]> {
    const { filters, limit, sort } = options;
    const {
      receiverId,
      senderId,
      sendTimeGe,
      sendTimeLe,
      system,
      participantIdsHas,
    } = filters ?? {};

    const brokenMails = await this.#mailController.mailControllerFind(
      participantIdsHas,
      receiverId,
      senderId,
      sendTimeGe,
      sendTimeLe,
      system,
      limit,
      sort?.map((v) => v.join(":")).join(","),
    );

    const mails = await Promise.all(
      brokenMails.map(async (brokenMail): Promise<Mail> => {
        const mail = fixDate(brokenMail, [
          "collectionTime",
          "deletionTime",
          "sendTime",
        ]);
        const [receiver, sender] = await Promise.all([
          this.getCachedAccountById(mail.receiverId),
          this.getCachedAccountById(mail.senderId),
        ]);

        return {
          ...mail,
          receiver,
          sender,
        };
      }),
    );

    return mails;
  }

  #myCachedAccount: Promise<Account> | undefined;

  async getMyAccount(): Promise<Account> {
    const myAccount = this.#accountController
      .accountControllerFindMy()
      .then((brokenAccount) =>
        fixDate(brokenAccount, [
          "lastBoughtCheaperBooster",
          "lastFreePvpDecksReroll",
          "lastOnline",
          "lastQuestGet",
          "lastQuestReroll",
          "lastSelectedDailyQuestPoolChange",
        ]),
      );

    if (typeof this.#myCachedAccount === "undefined") {
      this.#myCachedAccount = myAccount;
    }

    return myAccount;
  }

  async getMyCachedAccount(): Promise<Account> {
    const cachedAccount = this.#myCachedAccount;

    if (typeof cachedAccount === "undefined") {
      return this.getMyAccount();
    } else {
      return cachedAccount;
    }
  }

  async getReports(options: GetReportsOptions): Promise<Report[]> {
    const { filters, limit, offset, sort } = options;
    const { reporteeId, reporterId, status } = filters ?? {};
    const reportsWithoutRelations =
      await this.#reportController.reportControllerFind(
        reporteeId,
        reporterId,
        status,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      reportsWithoutRelations.map(async (brokenReport) => {
        const report = fixDate(brokenReport, ["reportTime"]);
        const [reportee, reporter] = await Promise.all([
          this.getCachedAccountById(report.reporteeId),
          this.getCachedAccountById(report.reporterId),
        ]);

        return {
          ...report,
          reportee,
          reporter,
        };
      }),
    );
  }

  async getScratchCodes(
    options: GetScratchCodesOptions,
  ): Promise<ScratchCode[]> {
    const { filters, limit, sort } = options;
    const { availableFromGe, availableFromLe, groupIdIn } = filters ?? {};
    const scratchCodesWithoutRelations =
      await this.#scratchCodeController.scratchCodeControllerFind(
        availableFromGe,
        availableFromLe,
        groupIdIn?.join(","),
        limit,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      scratchCodesWithoutRelations.map(async (brokenScratchCode) => {
        const scratchCode = fixDate(brokenScratchCode, [
          "availableFrom",
          "availableTo",
          "creationTime",
        ]);
        const creator = await this.getCachedAccountById(scratchCode.creatorId);

        return {
          ...scratchCode,
          creator,
        };
      }),
    );
  }

  async getScratchCodeGroups(
    options: GetScratchCodeGroupsOptions,
  ): Promise<ScratchCodeGroup[]> {
    const { filters, limit, sort } = options;
    const { creationTimeGe, creationTimeLe } = filters ?? {};
    const scratchCodeGroupsWithoutRelations =
      await this.#scratchCodeController.scratchCodeControllerFindGroups(
        creationTimeGe,
        creationTimeLe,
        limit,
        sort?.map((v) => v.join(":")).join(","),
      );

    return Promise.all(
      scratchCodeGroupsWithoutRelations.map(async (brokenScratchCodeGroup) => {
        const scratchCode = fixDate(brokenScratchCodeGroup, ["creationTime"]);
        const creator = await this.getCachedAccountById(scratchCode.creatorId);

        return {
          ...scratchCode,
          creator,
        };
      }),
    );
  }

  async getStatMostReported(options: GetStatOptions): Promise<StatReported[]> {
    const { filters, limit } = options;
    const { timeGe, timeLe } = filters;
    const statReportedWithoutRelations =
      await this.#statController.statControllerFindMostReported(
        timeGe,
        timeLe,
        limit,
      );

    const accountIds = new Set<number>();

    for (const stat of statReportedWithoutRelations) {
      accountIds.add(stat.accountId);

      for (const reported of stat.reportedBy) {
        accountIds.add(reported.accountId);
      }
    }

    const accounts = new Map<number, Account>();

    await Promise.all(
      Array.from(accountIds).map(async (accountId) => {
        accounts.set(accountId, await this.getCachedAccountById(accountId));
      }),
    );

    return statReportedWithoutRelations.map((stat) => {
      const account = accounts.get(stat.accountId)!;
      const reportedBy = stat.reportedBy.map((reported) => ({
        ...reported,
        account: accounts.get(reported.accountId)!,
      }));

      return {
        ...stat,
        reportedBy,
        account,
      };
    });
  }

  async getStatMostUnbalancedTrades(
    options: GetStatOptions,
  ): Promise<StatMostUnbalancedTrades[]> {
    const { filters, limit } = options;
    const { timeGe, timeLe } = filters;
    const statMostUnbalancedTradesWithoutRelations =
      await this.#statController.statControllerFindMostUnbalancedTrades(
        timeGe,
        timeLe,
        limit,
      );

    const accountIds = new Set<number>();

    for (const stat of statMostUnbalancedTradesWithoutRelations) {
      accountIds.add(stat.lhsAccountId);
      accountIds.add(stat.rhsAccountId);
    }

    const accounts = new Map<number, Account>();

    await Promise.all(
      Array.from(accountIds).map(async (accountId) => {
        accounts.set(accountId, await this.getCachedAccountById(accountId));
      }),
    );

    const boosters = await this.getCachedBoosters();

    return statMostUnbalancedTradesWithoutRelations.map((stat) => {
      const lhsAccount = accounts.get(stat.lhsAccountId)!;
      const rhsAccount = accounts.get(stat.rhsAccountId)!;

      return {
        ...stat,
        netBoostersFromLeftToRight: stat.netBoostersFromLeftToRight.map(
          (netBooster) => ({
            ...netBooster,
            booster: boosters.find((v) => v.id === netBooster.boosterId)!,
          }),
        ),
        lhsAccount,
        rhsAccount,
      } satisfies StatMostUnbalancedTrades;
    });
  }

  async getStatMostUnbalancedTradesAggregated(
    options: GetStatOptions,
  ): Promise<StatMostUnbalancedTradesAggregated[]> {
    const { filters, limit } = options;
    const { timeGe, timeLe } = filters;
    const statMostUnbalancedTradesAggregatedWithoutRelations =
      await this.#statController.statControllerFindMostUnbalancedTradesAggregated(
        timeGe,
        timeLe,
        limit,
      );

    const accountIds = new Set<number>();

    for (const stat of statMostUnbalancedTradesAggregatedWithoutRelations) {
      accountIds.add(stat.lhsAccountId);

      for (const rhsAccountId of stat.rhsAccountIds) {
        accountIds.add(rhsAccountId);
      }
    }

    const accounts = new Map<number, Account>();

    await Promise.all(
      Array.from(accountIds).map(async (accountId) => {
        accounts.set(accountId, await this.getCachedAccountById(accountId));
      }),
    );

    const boosters = await this.getCachedBoosters();

    return statMostUnbalancedTradesAggregatedWithoutRelations.map((stat) => {
      const lhsAccount = accounts.get(stat.lhsAccountId)!;
      const rhsAccounts = stat.rhsAccountIds.map(
        (rhsAccountId) => accounts.get(rhsAccountId)!,
      );

      return {
        ...stat,
        netBoostersFromLeftToRight: stat.netBoostersFromLeftToRight.map(
          (netBooster) => ({
            ...netBooster,
            booster: boosters.find((v) => v.id === netBooster.boosterId)!,
          }),
        ),
        lhsAccount,
        rhsAccounts,
      } satisfies StatMostUnbalancedTradesAggregated;
    });
  }

  async getStatMultiUserSystems(
    options: GetStatOptions,
  ): Promise<StatMultiUserSystem[]> {
    const { filters, limit } = options;
    const { timeGe, timeLe } = filters;
    const statMultiUserSystemsWithoutRelations =
      await this.#statController.statControllerFindMultiUserSystems(
        timeGe,
        timeLe,
        limit,
      );

    return Promise.all(
      statMultiUserSystemsWithoutRelations.map(async (stat) => {
        const accounts = await Promise.all(
          stat.accountIds.map((accountId) =>
            this.getCachedAccountById(accountId),
          ),
        );

        return {
          ...stat,
          accounts,
        };
      }),
    );
  }

  async getTransactions(
    options: GetTransactionsOptions,
  ): Promise<Transaction[]> {
    const { filters, limit, sort } = options;
    const {
      timeOfTransactionGe,
      timeOfTransactionLe,
      typeIn,
      participantIdsHas,
    } = filters ?? {};

    const transactions =
      await this.#transactionController.transactionControllerFind(
        participantIdsHas?.filter((v) => v !== undefined) as
          | number[]
          | undefined,
        timeOfTransactionGe,
        timeOfTransactionLe,
        typeIn,
        limit,
        sort?.map((v) => v.join(":")).join(","),
      );

    return transactions.map((transaction) =>
      fixDate(transaction, ["timeOfTransaction"]),
    );
  }

  async login(email: string, password: string): Promise<{ token: string }> {
    return this.#userController.userControllerLogin({
      email,
      password,
    });
  }

  async updateAccount(options: UpdateAccountOptions): Promise<void> {
    const { id, name } = options;

    await this.#accountController.accountControllerUpdateById(id, {
      name,
    });
  }

  async updateDeck(options: UpdateDeckOptions): Promise<void> {
    const { id, name } = options;

    await this.#deckController.deckControllerUpdateById(id, {
      name,
    });
  }

  async updateReport(options: UpdateReportOptions): Promise<void> {
    const { description, id, status } = options;

    await this.#reportController.reportControllerUpdateById(id, {
      description,
      status,
    });
  }

  async updateScratchCode(options: UpdateScratchCodeOptions): Promise<void> {
    const { availableFrom, availableTo, id, remainingUses } = options;

    await this.#scratchCodeController.scratchCodeControllerUpdateById(id, {
      availableFrom,
      availableTo,
      remainingUses,
    });
  }

  async wipeAccount(options: WipeAccountOptions): Promise<void> {
    const { id, reason } = options;

    await this.#accountController.accountControllerWipeById(
      {
        reason,
      },
      id,
    );
  }
}
